.navbar-wrapper {
  display: flex;
  align-items: center;
  background-color: #e0e0eb;
}

.navbar-links {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.others {
  padding: 10px;
}

.NavLink {
  color: black;
  font-weight: 500;
  text-decoration: none;
  padding-left: 15px;
}

.mobile-menu-icon {
  display: none;
  cursor: pointer;
}

.bar2 {
  display: block;
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px auto;
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 600px) {
  .navbar-links.active {
    display: flex;
  }

  .navbar-links.negative {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
  }

  .navbar-links {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 10px;
  }

  .others {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 0px;
  }
}
